
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $space-sm;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: $space-xxl;
}
