
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.card {
  display: flex;
  height: 400px;
  width: 400px;
  font-size: clamp(3rem, 2.5vw, 3.25rem);
  padding: $space-lg;
  font-family: $font-headline;
}

.text {
  margin: auto 0;
}
